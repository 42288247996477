import { EDITOR_SYNC_TIMEOUT_MS } from '../../routes/videoStory/constants'
import { sleep, uniqueId } from '../helpers'

const storyInternalFields = ['isTooComplex', 'initial']

const isFieldDefined = (field) => typeof field === 'string'

const resetStory = (slide) => {
  const story = (slide.story = slide.story || {})
  delete story.isTooComplex
  if (story.header?.length) story.header = ''
  if (story.subHeader?.length) story.subHeader = ''
  if (story.list?.length) story.list = ['']
  if (story.images?.length) story.images = Array(story.images.length).fill(null)
}

const isComplexStory = (story) => story?.isTooComplex === true

const isCanvasEmptyForStory = (story) =>
  !isComplexStory(story) && Object.keys(story).filter((key) => !storyInternalFields.includes(key)).length === 0

const isEmptySlideStory = (slide) =>
  !slide.speech && !slide.story.list?.filter(Boolean).length && !slide.story.header && !slide.story.subheader

const makeLayoutFromSlide = ({ canvas, story, avatar }) => {
  return { ...canvas, story, avatar, id: uniqueId() }
}

const formatInnerHtmlToText = (innerHTML) => {
  return !innerHTML || innerHTML === '<br>'
    ? ''
    : innerHTML
        .replaceAll('<br>', '\n')
        .replaceAll('&nbsp;', ' ')
        // Convert tags with empty inner content to self-closing tags
        .replace(/<(\w+)([^>]*)><\/\1>/g, '<$1$2 />')
        // return makr tags back
        .replace(/<m([^>]*)>/g, '<mark$1>')
}

const ifLayoutsSimilar = (layout1, layout2) => {
  // amount of objects should be almost same (not more than 1 object diff)
  if (Math.abs(layout1.objects.length - layout2.objects.length) > 1) return false
  let matchedObjects = 0
  if (layout1.objects && layout2.objects)
    for (const object of layout1.objects) {
      for (const object2 of layout2.objects) {
        if (object.type === object2.type && object.left === object2.left && object.top === object2.top) {
          matchedObjects++
        }
      }
    }
  // if ()console.log(layout1.id, layout2.id, layout1.objects.length, layout2.objects.length, matchedObjects)

  // for smaller slides all objects should match
  if (layout1.objects.length <= 3) return matchedObjects === layout1.objects.length
  // for bigger slides at least 2 objects should match and more than 60% objects coordinates matched
  return matchedObjects >= 2 && matchedObjects / layout1.objects.length > 0.6
}

const pasteTextToActiveElement = (text) => {
  const selection = window.getSelection()
  if (selection?.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    range.deleteContents()

    const textNode = document.createTextNode(text)
    range.insertNode(textNode)
    range.setStartAfter(textNode)
    range.setEndAfter(textNode)
    selection.removeAllRanges()
    selection.addRange(range)
  } else {
    document.execCommand('insertText', false, text)
  }
}

const waitForElementInDOM = async (selector) => {
  let i = 0
  while (i++ < 10) {
    await sleep(EDITOR_SYNC_TIMEOUT_MS)
    const el = document.querySelector(selector)
    if (el) return el
  }
}

export {
  storyInternalFields,
  isFieldDefined,
  resetStory,
  isComplexStory,
  isCanvasEmptyForStory,
  makeLayoutFromSlide,
  formatInnerHtmlToText,
  ifLayoutsSimilar,
  pasteTextToActiveElement,
  waitForElementInDOM,
  isEmptySlideStory,
}
