module.exports = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://apis.elai.io',
  playerUrl: process.env.REACT_APP_PLAYER_URL || 'https://player.elai.io',
  apiPexelsKey: process.env.REACT_APP_PEXELS_KEY,
  apiGiphyKey: process.env.REACT_APP_GIPHY_KEY,
  globalProdEnv: process.env.NODE_ENV === 'production' && !process.env.REACT_APP_LOGO_URL, // this is not dedicated setup (prod, staging, dev etc)
  isTesting: process.env.NODE_ENV === 'development' || process.env.CI === 'true', // performance e2e testing process
  signupLinksEnabled: process.env.REACT_APP_DISABLE_SIGNUP_LINKS !== 'true', //used for dedicated setups
  hideElaiMentions: process.env.REACT_APP_HIDE_ELAI_MENTIONS === 'true', //used for dedicated setups
  hideAvatarsCreation: process.env.REACT_APP_HIDE_AVATARS_CREATION === 'true', //used for dedicated setups
  hideAcademy: process.env.REACT_APP_HIDE_ACADEMY === 'true', //used for dedicated setups
  dynamicLogoUrl: process.env.REACT_APP_LOGO_URL, //used for dedicated setups
  dynamicWhiteLogoUrl: process.env.REACT_APP_LOGO_WHITE_URL, //used for dedicated setups
  dynamicFavicon: process.env.REACT_APP_FAVICON, //used for dedicated setups
  dynamicTitle: process.env.REACT_APP_TITLE, //used for dedicated setups
  defaultAvatarCode: process.env.REACT_APP_DEFAULT_AVATAR_CODE || 'gia.casual', //used for dedicated setups
}
