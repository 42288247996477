import { useCallback, useEffect } from 'react'
import { debounce } from 'throttle-debounce'
import { Button, Input, Row, Col, Switch, Tooltip } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useState } from 'react'
import Icon from '../../../../../components/Icon'
import SlideSelect from './slideSelect'
import { MAX_ANSWERS_COUNT, MIN_ANSWERS_COUNT, ANSWER_OBJECT_PLACEHOLDER } from '../constants'

const { TextArea } = Input

const QuestionControls = ({
  video,
  activeSlide,
  canvasActiveObject,
  setActiveObjectModifier,
  handleBackArrowClick,
}) => {
  const [questionText, setQuestionText] = useState(canvasActiveObject.getQuestionText())
  const [answers, setAnswers] = useState(canvasActiveObject.getAnswers())
  const [feedbackMessages, setFeedbackMessages] = useState({
    onCorrect: canvasActiveObject.onCorrect?.feedbackMessage,
    onWrong: canvasActiveObject.onWrong?.feedbackMessage,
  })

  const activeObjectAnswersGroupObjects = canvasActiveObject.patternSourceCanvas.getObjects()[0].getObjects()

  const saveQuestionText = (value) => {
    setActiveObjectModifier({ change: 'questionText', value })
  }

  const debounceSaveQuestionText = useCallback(
    debounce(500, (value) => saveQuestionText(value)),
    [],
  )

  const onChangeQuestionText = (event) => {
    const value = event.target.value
    setQuestionText(value)
    debounceSaveQuestionText(value)
  }

  const markCorrectAnswer = (id) => {
    setActiveObjectModifier({ change: 'markCorrectAnswer', value: id })
  }

  const unmarkCorrectAnswer = (id) => {
    setActiveObjectModifier({ change: 'unmarkCorrectAnswer', value: id })
  }

  const saveFeedbackMessages = (value, field) => {
    setActiveObjectModifier({ change: field, value: { ...canvasActiveObject[field], feedbackMessage: value } })
  }

  const handleChangeSlide = (value, field) => {
    setActiveObjectModifier({ change: field, value: { ...canvasActiveObject[field], slide: value } })
  }

  const debounceSaveFeedbackMessages = useCallback(
    debounce(500, (value, field) => saveFeedbackMessages(value, field)),
    [],
  )

  const onChangeFeedbackMessage = (value, field) => {
    setFeedbackMessages({ ...feedbackMessages, [field]: value })
    debounceSaveFeedbackMessages(value, field)
  }

  const onChangeFeedbackSwitch = (checked) => {
    setActiveObjectModifier({ change: 'feedback', value: checked })
  }

  const saveAnswer = (id, value) => {
    value = value || ANSWER_OBJECT_PLACEHOLDER
    setActiveObjectModifier({
      change: 'answerText',
      value: { id, text: value },
    })
  }

  const debounceSaveAnswer = useCallback(
    debounce(300, (id, value) => saveAnswer(id, value)),
    [],
  )

  const onChangeAnswer = (id, value) => {
    setAnswers((answers) => {
      return answers.map((answer) => (answer.id === id ? { ...answer, text: value } : answer))
    })
    debounceSaveAnswer(id, value)
  }

  const addAnswer = () => {
    setActiveObjectModifier({ change: 'addAnswer' })
  }

  const removeAnswer = (id) => {
    setActiveObjectModifier({ change: 'removeAnswer', value: id })
  }

  useEffect(() => {
    setAnswers(canvasActiveObject.getAnswers())
  }, [activeObjectAnswersGroupObjects.length])

  return (
    <div className="interactivity-question-controls">
      <div className="back-to-arrow" onClick={handleBackArrowClick}>
        <ArrowLeftOutlined /> <p>Return to interactivity</p>
      </div>
      <div className="controls-sections-wrapper">
        <div className="controls-section">
          <h4 className="controls-title">Content</h4>
          <p className="input-label">Question</p>
          <TextArea
            className="question-textarea"
            value={questionText}
            rows={3}
            maxLength={300}
            placeholder="Type a question"
            onChange={onChangeQuestionText}
          />
          <p className="answers-controls-title">Answers</p>
          <div className="answers-controls-wrapper">
            {answers.map(({ id, text }) => (
              <div key={id} className="answer-control">
                {canvasActiveObject.getAnswersType() === 'radio' ? (
                  <Tooltip
                    title={
                      canvasActiveObject.correctAnswers.includes(id) ? (
                        <p className="answer-tooltip-content">The answer has been set as correct.</p>
                      ) : (
                        'Set as the correct answer.'
                      )
                    }
                    mouseEnterDelay={1}
                  >
                    {canvasActiveObject.correctAnswers.includes(id) ? (
                      <Button
                        className="btn-radio marked"
                        icon={<Icon name="check" />}
                        size="small"
                        shape="circle"
                        onClick={() => canvasActiveObject.correctAnswers.length !== 1 && markCorrectAnswer(id)}
                      />
                    ) : (
                      <Button className="btn-radio" size="small" shape="circle" onClick={() => markCorrectAnswer(id)} />
                    )}
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Select the correct answer(s) by ticking the checkboxes. A video viewer's answer will be marked as correct only if the selected options correspond to the checkboxes you have chosen. Otherwise, the answer will be considered incorrect"
                    mouseEnterDelay={1}
                  >
                    {canvasActiveObject.correctAnswers.includes(id) ? (
                      <Button
                        className="btn-checkbox checked"
                        icon={<Icon name="check" />}
                        size="small"
                        onClick={() => unmarkCorrectAnswer(id)}
                      />
                    ) : (
                      <Button className="btn-checkbox" size="small" onClick={() => markCorrectAnswer(id)} />
                    )}
                  </Tooltip>
                )}
                <Input
                  value={text === ANSWER_OBJECT_PLACEHOLDER ? '' : text}
                  placeholder="Type an answer"
                  onChange={(e) => onChangeAnswer(id, e.target.value)}
                />
                <Button
                  className="btn-delete"
                  type="text"
                  icon={<Icon name="delete" />}
                  size="small"
                  disabled={answers.length === MIN_ANSWERS_COUNT}
                  onClick={() => removeAnswer(id)}
                />
              </div>
            ))}
          </div>
          <Button
            type="dashed"
            disabled={answers.length === MAX_ANSWERS_COUNT}
            icon={<Icon name="plus" />}
            block
            onClick={addAnswer}
          >
            Add answer
          </Button>
        </div>
        <div className="controls-section">
          <div className="title-info-wrapper controls-title-wrapper">
            <h4 className="controls-title-wrapped">Interactions</h4>
            <Tooltip title="Redirect users to different sections of your video based on their answers to create branching scenarios. A video viewer's answer will be marked as correct only if the selected options correspond to the checkboxes you have chosen. Otherwise, the answer will be considered incorrect.">
              <Icon name="info" />
            </Tooltip>
          </div>
          <SlideSelect
            slides={video.slides}
            activeSlide={activeSlide}
            title={
              <span>
                <Icon name="check" />
                <span>Correct answer</span>
              </span>
            }
            value={canvasActiveObject.onCorrect?.slide || null}
            handleChangeSlide={(value) => handleChangeSlide(value, 'onCorrect')}
          />
          <SlideSelect
            slides={video.slides}
            activeSlide={activeSlide}
            title={
              <span>
                <Icon name="cancel" />
                <span>Incorrect answer</span>
              </span>
            }
            value={canvasActiveObject.onWrong?.slide || null}
            handleChangeSlide={(value) => handleChangeSlide(value, 'onWrong')}
          />
        </div>
        <div className="controls-section">
          <Row align="middle" justify="space-between" className="controls-title-wrapper">
            <Col className="title-info-wrapper">
              <h4 className="controls-title-wrapped">Feedback</h4>
              <Tooltip title="You can show a message to users based on their answer. Message will be shown for 3 seconds before user will be redirected to the next slide.">
                <Icon name="info" />
              </Tooltip>
            </Col>
            <Col>
              <Switch checked={canvasActiveObject.feedback} onChange={onChangeFeedbackSwitch} />
            </Col>
          </Row>
          {canvasActiveObject.feedback && (
            <>
              <div className="input-labeled-wrapper">
                <p className="input-label">Correct message</p>
                <Input
                  value={feedbackMessages.onCorrect}
                  placeholder="Message for a correct answer"
                  onChange={(event) => onChangeFeedbackMessage(event.target.value, 'onCorrect')}
                />
              </div>
              <div className="input-labeled-wrapper">
                <p className="input-label">Incorrect message</p>
                <Input
                  value={feedbackMessages.onWrong}
                  placeholder="Message for an incorrect answer"
                  onChange={(event) => onChangeFeedbackMessage(event.target.value, 'onWrong')}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuestionControls
